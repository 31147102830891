<template>
  <div class="filter-options filter-options--influencer-admin-users">
    <v-row>
      <v-col v-for="item in filters" :key="item" cols="6" sm="4" md="3" lg="2">
        <component :is="item" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Import children components
const FilterIsDemo = () => import(/* webpackChunkName: "filter-is-demo-admin" */ "@/blocks/admin/FilterIsDemo")
const FilterCountry = () => import(/* webpackChunkName: "filter-country-admin" */ "@/blocks/admin/FilterCountry")
const FilterBalance = () => import(/* webpackChunkName: "filter-balance-admin" */ "@/blocks/admin/FilterBalance")
const FilterIsExpired = () => import(/* webpackChunkName: "filter-is-expired-admin" */ "@/blocks/admin/FilterIsExpired")
const FilterCanUseApi = () => import(/* webpackChunkName: "filter-can-use-api-admin" */ "@/blocks/admin/FilterCanUseApi")
const FilterIsInactive = () => import(/* webpackChunkName: "filter-is-inactive-admin" */ "@/blocks/admin/FilterIsInactive")
const FilterCreditType = () => import(/* webpackChunkName: "filter-credit-type-admin" */ "@/blocks/admin/FilterCreditType")
const FilterIsDisabled = () => import(/* webpackChunkName: "filter-is-disabled-admin" */ "@/blocks/admin/FilterIsDisabled")
const FilterLastActive = () => import(/* webpackChunkName: "filter-last-active-admin" */ "@/blocks/admin/FilterLastActive.vue")
const FilterIsWhitelisted = () => import(/* webpackChunkName: "filter-is-whitelisted-admin" */ "@/blocks/admin/FilterIsWhitelisted")
const FilterIncludeTeamMembers = () => import(/* webpackChunkName: "filter-include-team-members-admin" */ "@/blocks/admin/FilterIncludeTeamMembers")

// Export the SFC
export default {
  // Name of the component
  name: "FilterOptions",

  // Register children components
  components: {
    FilterIsDemo,
    FilterCountry,
    FilterBalance,
    FilterIsExpired,
    FilterCanUseApi,
    FilterIsInactive,
    FilterCreditType,
    FilterIsDisabled,
    FilterLastActive,
    FilterIsWhitelisted,
    FilterIncludeTeamMembers
  },

  // Define local data
  data: () => ({
    // List of filters
    filters: [
      "filter-last-active",
      "filter-is-demo",
      "filter-is-whitelisted",
      "filter-can-use-api",
      "filter-credit-type",
      "filter-country",
      "filter-balance",
      "filter-is-disabled",
      "filter-is-expired",
      "filter-is-inactive",
      "filter-include-team-members"
    ]
  })
}
</script>

<style lang="stylus">
.filter-options
  .v-icon
    color var(--v-secondary)

  .filter-title
    color #4c4c4c
</style>
